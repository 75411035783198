var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _vm._m(0),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-50 md-small-size-50 md-xsmall-size-100 md-size-50",
        },
        [
          _c(
            "pricing-card",
            {
              attrs: {
                "card-class": "md-card-plain",
                "icon-color": "icon-white",
              },
            },
            [
              _c("md-icon", { attrs: { slot: "icon" }, slot: "icon" }, [
                _vm._v("remove_from_queue"),
              ]),
              _c(
                "h3",
                {
                  staticClass: "title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _vm._v("Básico "),
                  _c("br"),
                  _c("span", { staticClass: "price" }, [_vm._v("Grátis")]),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "card-description",
                  attrs: { slot: "description" },
                  slot: "description",
                },
                [_vm._v("Pacote grátis com limitações de funcionalidades.")]
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-white md-round",
                  attrs: { slot: "footer" },
                  on: {
                    click: function ($event) {
                      return _vm.choicePlan("Free")
                    },
                  },
                  slot: "footer",
                },
                [_vm._v("Escolher plano")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-50 md-small-size-50 md-xsmall-size-100 md-size-50",
        },
        [
          _c(
            "pricing-card",
            { attrs: { "icon-color": "icon-info" } },
            [
              _c("md-icon", { attrs: { slot: "icon" }, slot: "icon" }, [
                _vm._v("important_devices"),
              ]),
              _c(
                "h3",
                {
                  staticClass: "title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _vm._v("Premium "),
                  _c("br"),
                  _c("span", { staticClass: "price" }, [_vm._v("R$199,99")]),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "card-description",
                  attrs: { slot: "description" },
                  slot: "description",
                },
                [
                  _vm._v(
                    "Pacote premium com todas as funcionalidades liberadas."
                  ),
                ]
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-info md-round",
                  attrs: { slot: "footer" },
                  on: {
                    click: function ($event) {
                      return _vm.choicePlan("Premium")
                    },
                  },
                  slot: "footer",
                },
                [_vm._v("Escolher plano")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 text-center",
      },
      [_c("h2", { staticClass: "title" }, [_vm._v("Escolha um dos planos")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
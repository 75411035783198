<template>
  <div class="md-layout">
    <!-- <div > -->
      <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 text-center">
        <h2 class="title">Escolha um dos planos</h2>
        <!-- <h5 class="description">You have Free Unlimited Updates and Premium Support on each package.</h5> -->
      </div>
    <!-- </div> -->
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-small-size-50 md-xsmall-size-100 md-size-50">
        <pricing-card card-class="md-card-plain" icon-color="icon-white">
          <!-- <h6 slot="category" class="category">Grátis</h6> -->
          <md-icon slot="icon">remove_from_queue</md-icon>
          <h3 slot="title" class="title">Básico <br><span class="price">Grátis</span></h3>
          <p slot="description" class="card-description">Pacote grátis com limitações de funcionalidades.</p>
          <md-button slot="footer" class="md-white md-round" @click="choicePlan('Free')">Escolher plano</md-button>
        </pricing-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-small-size-50 md-xsmall-size-100 md-size-50">
        <pricing-card icon-color="icon-info">
          <!-- <h6 slot="category" class="category">Small Company</h6> -->
          <md-icon slot="icon">important_devices</md-icon>
          <h3 slot="title" class="title">Premium <br><span class="price">R$199,99</span></h3>
          <p slot="description" class="card-description">Pacote premium com todas as funcionalidades liberadas.</p>
          <md-button slot="footer" class="md-info md-round" @click="choicePlan('Premium')">Escolher plano</md-button>
        </pricing-card>
      </div>

    </div>
  </div>
</template>
<script>
    import {
        PricingCard
    } from '@/components'
    export default {
        components: {
            PricingCard
        },
        data() {
          
        },
        methods: {
          choicePlan(type) {
            if(type.trim() == "Free") {
              sessionStorage.setItem('plans', JSON.stringify({type: "Free"}))
              this.$router.push('forms/wizard')
            } else if(type.trim() == "Premium") {
              pagarme.client.connect({ api_key: 'ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S' })
                .then(client => client.plans.all({ count: 10, page: 1 }))
                .then((plans) => {
                  plans[0].type = "Premium"
                  sessionStorage.setItem('plans', JSON.stringify(plans[1]))
                  this.$router.push('forms/wizard')
                })
            }
          }
        }
    }
</script>
<style scoped>
    .text-center {
        text-align: center;
    }
    .btn-plan:visited {
      color: #a1a1a1;
    }
    .btn-plan2:visited {
      color: #fff;
    }
    .price {
      font-size: 15px;
    }
</style>